<template>
  <view-item title="喷涂对账">
    <template #operation>
      <en-button type="primary" @click="operation.receipt.click"> 收款单 </en-button>

      <template v-if="store.userTenantId === 'ENOCH'">
        <en-button type="primary" @click="operation.add.click"> 创建对账单 </en-button>
        <template v-if="form.data.status?.code && ['P', 'R'].includes(form.data.status.code)">
          <button-ajax :disabled="form.loading" :method="operation.submit.click">保存</button-ajax>
          <button-delete type="danger" :link="false" :method="operation.delete.click">删除</button-delete>
          <button-ajax :disabled="form.loading" :method="operation.commit.click">发送网点</button-ajax>
        </template>
        <button-ajax
          v-if="form.data.status?.code && ['C'].includes(form.data.status.code) && (!form.data.processedProgress || !form.data.workOrders.length)"
          :disabled="form.loading"
          :method="operation.reverse.click"
        >
          退回
        </button-ajax>
        <button-ajax
          v-if="form.data.status?.code && ['C'].includes(form.data.status.code)"
          :disabled="form.loading"
          :method="operation.exportReceiptDetail.click"
        >
          导出结算清单
        </button-ajax>
      </template>

      <template v-else>
        <button-ajax v-if="form.data.status?.code && ['C'].includes(form.data.status.code)" :disabled="form.loading" :method="operation.active.click">
          一键对账
        </button-ajax>
        <button-ajax v-if="form.data.status?.code && ['C'].includes(form.data.status.code)" :disabled="form.loading" :method="operation.export.click">
          提交对账单
        </button-ajax>
        <en-button v-if="form.data.id" :disabled="form.loading" @click="operation.submit.click"> 导出对账单 </en-button>
        <en-button
          v-if="form.data.status?.code && ['C'].includes(form.data.status.code)"
          :disabled="form.loading"
          @click="operation.exportReceipt.click"
        >
          导出结算单
        </en-button>
      </template>
    </template>

    <template #manifest>
      <table-manifest
        code="SPBSNSTATFD"
        :ajax="
          store.userTenantId === 'ENOCH'
            ? { action: 'GET /enospray/statement/paint/business/enoch/query' }
            : { action: 'GET /enospray/statement/paint/business/query' }
        "
        :props="{ start: 'startDate', end: 'endDate' }"
        @row-click="manifest.row.click"
        @expand-click="dialog.visible = true"
      >
        <template #form="{ data }">
          <en-form-item label="网点">
            <select-maintain
              v-model="data.tenantId"
              :ajax="{ action: 'GET /enospray/common/tenant', params: (params, shortName) => (params.payload = { shortName }) }"
              :props="{ label: 'shortName', value: 'id' }"
              remote
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="单据号">
            <en-input v-model="data.serialNo"></en-input>
          </en-form-item>
          <en-form-item label="对账状态">
            <select-maintain
              v-model="data.status"
              :ajax="{ action: 'GET /enospray/common/lookup/:lookupType', params: (params) => (params.paths = ['PSSTAT']) }"
              :props="{ label: 'message', value: 'code' }"
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="业务员">
            <en-input v-model="data.salesmanName"></en-input>
          </en-form-item>
          <en-form-item label="技术督导">
            <en-input v-model="data.supervisorName"></en-input>
          </en-form-item>
        </template>
      </table-manifest>
    </template>

    <view-item-content :disabled="!form.data.id">
      <en-card v-loading="form.loading">
        <en-form label-position="left" inline>
          <en-form-item label="网点" v-if="store.userTenantId === 'ENOCH'">
            <en-input :model-value="form.data.tenant?.shortName" disabled></en-input>
          </en-form-item>
          <en-form-item label="起始日期">
            <en-input :model-value="form.data.startDate" disabled></en-input>
          </en-form-item>
          <en-form-item label="结束日期">
            <en-input :model-value="form.data.endDate" disabled></en-input>
          </en-form-item>
          <en-form-item label="单据">
            <en-input :model-value="form.data.serialNo" disabled></en-input>
          </en-form-item>
          <en-form-item label="备注">
            <en-input :model-value="form.data.comment" disabled></en-input>
          </en-form-item>
        </en-form>

        <en-table :data="[form.summary]">
          <en-table-column label="车辆数" prop="vehicleCount"></en-table-column>
          <en-table-column label="钣面">
            <en-table-column
              v-for="item of form.summary.chargingStandards"
              :label="(item.paintType?.message ?? '') + (item.chargingStandard?.message ?? '')"
            >
              <template #default>{{ item.square }} </template>
            </en-table-column>
            <en-table-column label="合计" prop="totalSquare"></en-table-column>
          </en-table-column>
          <en-table-column label="材料成本">
            <en-table-column label="色母" prop="paintPrimeCost">
              <template #default="{ row }">{{ formatMoney(row.paintPrimeCost) }}</template>
            </en-table-column>
            <en-table-column label="领料" prop="materialPrimeCost">
              <template #default="{ row }">{{ formatMoney(row.materialPrimeCost) }}</template>
            </en-table-column>
          </en-table-column>
          <en-table-column label="产值合计" prop="totalAmount">
            <template #default="{ row }">{{ formatMoney(row.totalAmount) }}</template>
          </en-table-column>
          <en-table-column label="奖励金额">
            <en-table-column
              v-if="form.data.businessSettlementPolicy && ['EW'].includes(form.data.businessSettlementPolicy.code)"
              label="月度履约激励"
              prop="monthlyPromissoryAmount"
            >
              <template #default="{ row }">{{ formatMoney(row.monthlyPromissoryAmount) }}</template>
            </en-table-column>
            <en-table-column
              v-if="
                (form.data.businessSettlementPolicy && ['BN'].includes(form.data.businessSettlementPolicy.code)) ||
                (form.data.bonusPolicy && ['M', 'Q'].includes(form.data.bonusPolicy.code))
              "
              label="当月商务政策"
              prop="monthlyPolicyBonusAmount"
            >
              <template #default="{ row }">{{ formatMoney(row.monthlyPolicyBonusAmount) }}</template>
            </en-table-column>
            <en-table-column
              v-if="
                form.data.endDate &&
                [1, 4, 7, 10].includes(Number(form.data.endDate.split('-')[1])) &&
                form.data.businessSettlementPolicy &&
                ['BN', 'EW'].includes(form.data.businessSettlementPolicy.code) &&
                form.data.bonusPolicy &&
                form.data.bonusPolicy.code !== 'M'
              "
              label="季度商务政策"
              prop="seasonPolicyBonusAmount"
            >
              <template #default="{ row }">{{ formatMoney(row.seasonPolicyBonusAmount) }}</template>
            </en-table-column>
            <en-table-column
              v-if="
                form.data.endDate &&
                [1].includes(Number(form.data.endDate.split('-')[1])) &&
                form.data.businessSettlementPolicy &&
                ['OS', 'BN'].includes(form.data.businessSettlementPolicy.code)
              "
              label="年度商务政策"
            >
              <template #default="{ row }">{{ formatMoney(row.yearPolicyBonusAmount) }}</template>
            </en-table-column>
            <en-table-column
              label="团队绩效"
              v-if="form.data.businessSettlementPolicy && ['NA', 'PX', 'DT', 'BN', 'EW'].includes(form.data.businessSettlementPolicy.code)"
            >
              <template #default="{ row }">{{ formatMoney(row.performanceBonusAmount) }}</template>
            </en-table-column>
          </en-table-column>
          <en-table-column label="赠送面"></en-table-column>
          <en-table-column label="赠送金额"></en-table-column>
          <en-table-column label="结算总金额调整值"></en-table-column>
          <en-table-column label="结算总金额"></en-table-column>
        </en-table>

        <en-form label-position="left" inline class="mt-5">
          <en-form-item>
            <div class="flex items-center gap-4">
              <span>本次对账删除工单:{{ form.summary.workordersIncludedLength }} 个</span>
              <span>钣面数:{{ form.summary.workordersSurfaceCount }},</span>
              <span>合计金额:{{ formatMoney(form.summary.workordersSurfaceAmount) }}</span>
              <span>结算金额: 0</span>
            </div>
          </en-form-item>
          <en-form-item label="结算总金额调整值">
            <en-input
              v-model="form.data.manualAdjustmentAmount"
              :disabled="
                store.userTenantId !== 'ENOCH' ||
                (form.data.status?.code === 'A' && !store.accessRightsHash.PAINT_STATEMENT_MANUAL_ADJUSTMENT_ENOCH) ||
                form.data.manualAdjusted?.value
              "
            ></en-input>
          </en-form-item>
          <en-form-item label="备注">
            <en-input
              v-model="form.data.comment"
              :disabled="
                store.userTenantId !== 'ENOCH' ||
                (form.data.status?.code === 'A' && !store.accessRightsHash.PAINT_STATEMENT_MANUAL_ADJUSTMENT_ENOCH) ||
                form.data.manualAdjusted?.value
              "
            ></en-input>
          </en-form-item>
          <en-form-item>
            <button-ajax
              :disabled="
                store.userTenantId !== 'ENOCH' ||
                (form.data.status?.code === 'A' && !store.accessRightsHash.PAINT_STATEMENT_MANUAL_ADJUSTMENT_ENOCH) ||
                form.data.manualAdjusted?.value
              "
              :method="operation.submit.click"
            >
              调整
            </button-ajax>
          </en-form-item>
        </en-form>
      </en-card>

      <en-card class="flex-1 overflow-auto" body-class="h-full">
        <flex-box>
          <template #default="{ height }">
            <en-table :data="form.data.workOrders" :height="height" :loading="form.loading">
              <en-table-column :selectable="form.workOrders.selection.selectable" type="selection" width="55"></en-table-column>
              <en-table-column label="操作" v-if="store.userTenantId === 'ENOCH'" width="80">
                <template #default="{ row }: { row: EnosprayStatementDefinitions['PaintBusinessStatementWorkOrderDto'] }">
                  <en-button
                    type="primary"
                    link
                    :disabled="form.disabled || row.workOrder?.rework?.value"
                    @click="
                      row.workOrder?.rework?.value ? form.workOrders.operation.exclude.click(row) : form.workOrders.operation.include.click(row)
                    "
                  >
                    {{ row.included?.value ? '删除' : '添加' }}
                  </en-button>
                </template>
              </en-table-column>
              <en-table-column label="工单号" prop="workOrder.serialNo"></en-table-column>
              <en-table-column label="车牌号" prop="workOrder.plateNo"></en-table-column>
              <en-table-column label="工单提交日期" prop="workOrder.committedDatetime"></en-table-column>
              <en-table-column label="收费类型">
                <template #default="{ row }: { row: EnosprayStatementDefinitions['PaintBusinessStatementWorkOrderDto'] }">
                  {{ `${row.workOrder?.paintType?.message}${row.workOrder?.chargingStandard?.message}` }}
                </template>
              </en-table-column>
              <en-table-column label="钣面数" prop="workOrder.grossSurface"></en-table-column>
              <en-table-column label="单据金额">
                <template #default="{ row }: { row: EnosprayStatementDefinitions['PaintBusinessStatementWorkOrderDto'] }">
                  {{ formatMoney(row.workOrder?.grossSurfaceAmount) }}
                </template>
              </en-table-column>
              <en-table-column label="成本占比">
                <template #default="{ row }: { row: EnosprayStatementDefinitions['PaintBusinessStatementWorkOrderDto'] }">
                  {{ formatPercent(row.workOrder?.costRatio) }}
                </template>
              </en-table-column>
              <en-table-column label="是否有联保项目" prop="workOrder.jointWarranty.message"></en-table-column>
              <en-table-column label="制单人" prop="workOrder.preparedBy"></en-table-column>
              <en-table-column label="班组" prop="workOrder.workingTeam"></en-table-column>
              <en-table-column label="备注" prop="comment">
                <template #default="{ row }: { row: EnosprayStatementDefinitions['PaintBusinessStatementWorkOrderDto'] }">
                  <en-input v-model="row.comment" :disabled="form.loading || form.disabled" @blur="form.workOrders.comment.blur(row)"></en-input>
                </template>
              </en-table-column>
              <en-table-column label="对账状态" v-if="store.userTenantId !== 'ENOCH'">
                <template #default="{ row }: { row: EnosprayStatementDefinitions['PaintBusinessStatementWorkOrderDto'] }">
                  <en-button
                    v-if="row.included?.value"
                    :disabled="form.loading || form.disabled || row.workOrder?.rework?.value"
                    :type="row.confirmed?.value ? 'success' : 'warning'"
                    link
                    @click="form.workOrders.operation.confirmed.click(row)"
                  >
                    {{ row.confirmed?.value ? '已对账' : '未对账' }}
                  </en-button>
                </template>
              </en-table-column>
            </en-table>
          </template>
        </flex-box>
      </en-card>
    </view-item-content>
  </view-item>

  <settlement-statement-paint-detail v-model="detail.visible" @confirm="detail.confirm"></settlement-statement-paint-detail>

  <settlement-statement-paint-dialog v-model="dialog.visible"></settlement-statement-paint-dialog>

  <settlement-statement-receipt-dialog v-model="receipt.visible"></settlement-statement-receipt-dialog>
</template>

<script lang="ts">
import { EnMessage, EnMessageBox } from '@enocloud/components'
import { calculator } from '@enocloud/utils'

import settlementStatementPaintDetail from '@spray/components/settlement-statement-paint-detail.vue'
import settlementStatementPaintDialog from '@spray/components/settlement-statement-paint-dialog.vue'
import settlementStatementReceiptDialog from '@spray/components/settlement-statement-receipt-dialog.vue'

export default factory({
  components: { settlementStatementPaintDetail, settlementStatementPaintDialog, settlementStatementReceiptDialog },

  config: {
    children: {
      operation: {
        add: {
          async click() {
            await this.dirtyCheck('form')
            this.form.init()
            this.detail.visible = true
          }
        },
        receipt: {
          click() {
            this.receipt.visible = true
          }
        },
        submit: {
          async click() {
            await this.form.submit()
            return this.form[this.store.userTenantId === 'ENOCH' ? 'getByEnoch' : 'getByTenant']()
          }
        },
        delete: {
          async click() {
            await this.form.delete()
            return this.form[this.store.userTenantId === 'ENOCH' ? 'getByEnoch' : 'getByTenant']()
          }
        },
        commit: {
          async click() {
            await this.form.commit()
            return this.form[this.store.userTenantId === 'ENOCH' ? 'getByEnoch' : 'getByTenant']()
          }
        },
        reverse: {
          async click() {
            await this.form.reverse()
            return this.form[this.store.userTenantId === 'ENOCH' ? 'getByEnoch' : 'getByTenant']()
          }
        },
        active: {
          async click() {
            if (!this.form.workOrders.selection.data.length) return EnMessage.warning('请勾选对账工单')
            await this.form.active()
            return this.form[this.store.userTenantId === 'ENOCH' ? 'getByEnoch' : 'getByTenant']()
          }
        },
        audit: {
          async click() {
            const { workOrders } = this.form.data
            const pending = workOrders.filter((w) => !w.confirmed?.value)
            const serialNos = pending.map((p) => p.workOrder?.serialNo).join(',')
            if (serialNos.length) return EnMessageBox.alert(`${serialNos}未对账`, '提示')
            await this.form.audit()
            return this.form[this.store.userTenantId === 'ENOCH' ? 'getByEnoch' : 'getByTenant']()
          }
        },
        export: {
          click() {
            window.open(
              this.store.userTenantId === 'ENOCH'
                ? `/enospray/statement/paint/business/enoch/${this.form.data.id}/export`
                : `/enospray/statement/paint/business/${this.form.data.id}/export`
            )
          }
        },
        exportReceipt: {
          click() {
            window.open(
              this.store.userTenantId === 'ENOCH'
                ? `/enospray/statement/paint/business/enoch/${this.form.data.id}/settlement/export`
                : `/enospray/statement/paint/business/${this.form.data.id}/settlement/export`
            )
          }
        },
        exportReceiptDetail: {
          click() {
            window.open(
              this.store.userTenantId === 'ENOCH'
                ? `/enospray/statement/paint/business/enoch/${this.form.data.id}/detail/export`
                : `/enospray/statement/paint/business/${this.form.data.id}/detail/export`
            )
          }
        }
      },
      manifest: {
        row: {
          async click(row: EnosprayStatementDefinitions['PaintBusinessStatementQueryDto']) {
            await this.dirtyCheck('form')
            this.form.init()
            this.form.data.id = row.id
            this.form[this.store.userTenantId === 'ENOCH' ? 'getByEnoch' : 'getByTenant']()
          }
        }
      },
      form: {
        ajax: {
          getByTenant: {
            action: 'GET /enospray/statement/paint/business/:paintStatementId',
            data: 'object',
            loading: true,
            init: true,
            dirty: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          getByEnoch: {
            action: 'GET /enospray/statement/paint/business/enoch/:paintStatementId',
            data: 'object',
            loading: true,
            dirty: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          submit: {
            action: 'GET /enospray/statement/paint/business/enoch/:paintStatementId',
            loading: true,
            params(params) {}
          },
          delete: {
            action: 'DELETE /enospray/statement/paint/business/enoch/:paintStatementId',
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          commit: {
            action: 'POST /enospray/statement/paint/business/enoch/:paintStatementId/commit',
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          reverse: {
            action: 'POST /enospray/statement/paint/business/enoch/:paintStatementId/commit/reverse',
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          active: {
            action: 'PUT /enospray/statement/paint/business/:paintStatementId/active',
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
              params.data = this.form.workOrders.selection.data.map((item) => {
                const res = Object.assign({}, item)
                res.confirmed = { code: 'Y', message: '', type: '', description: '' }
                return res
              })
            }
          },
          cancel: {
            action: 'PUT /enospray/statement/paint/business/:paintStatementId/cancel',
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
              params.data = this.form.workOrders.selection.data.map((item) => {
                const res = Object.assign({}, item)
                res.confirmed = { code: 'N', message: '', type: '', description: '' }
                return res
              })
            }
          },
          include: {
            action: 'PUT /enospray/statement/paint/business/enoch/:paintStatementId/include',
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
              params.data = this.form.workOrders.selection.data
            }
          },
          exclude: {
            action: 'PUT /enospray/statement/paint/business/enoch/:paintStatementId/exclude',
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
              params.data = this.form.workOrders.selection.data
            }
          },
          commentByTenant: {
            action: 'PUT /enospray/statement/paint/business/:paintStatementId/comment',
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          commentByEnoch: {
            action: 'PUT /enospray/statement/paint/business/enoch/:paintStatementId/comment',
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          audit: {
            action: 'PUT /enospray/statement/paint/business/:paintStatementId/audit',
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          }
        },
        computed: {
          summary() {
            const {
              chargingStandards = [],
              totalCount = 0,
              totalSquare = 0,
              totalAmount = 0,
              primeCosts = [],
              manualAdjustmentAmount = 0,
              monthlyPolicyBonusAmount = 0,
              performanceBonusAmount = 0,
              yearPolicyBonusAmount = 0,
              donateSquare = 0,
              donateAmount = 0,
              settlementAmount = 0,
              seasonPolicyBonusAmount = 0,
              monthlyPromissoryAmount = 0,
              workOrders = []
            } = this.form.data

            const workordersIncluded = workOrders.filter((w) => w.included?.value)

            return {
              vehicleCount: totalCount,
              chargingStandards: chargingStandards.filter((c) => c.square),
              totalSquare,
              totalAmount,
              paintPrimeCost: primeCosts.filter((p) => p.type?.code === 'P').reduce((sum, p) => calculator.add(sum, p.primeCost), 0),
              materialPrimeCost: primeCosts.filter((p) => p.type?.code === 'A').reduce((sum, p) => calculator.add(sum, p.primeCost), 0),
              manualAdjustmentAmount,
              monthlyPolicyBonusAmount,
              performanceBonusAmount,
              yearPolicyBonusAmount,
              donateSquare,
              donateAmount,
              settlementAmount,
              seasonPolicyBonusAmount,
              monthlyPromissoryAmount,
              workordersLength: workOrders.length,
              workordersIncludedLength: workordersIncluded.length,
              workordersSurfaceCount: workordersIncluded.reduce((sum, w) => calculator.add(sum, w.workOrder?.grossSurface), 0),
              workordersSurfaceAmount: workordersIncluded.reduce((sum, w) => calculator.add(sum, w.workOrder?.grossSurfaceAmount), 0)
            }
          },
          disabled() {
            return this.form.data.status && ['S', 'A'].includes(this.form.data.status.code)
          }
        },
        children: {
          workOrders: {
            selection: {
              data: [] as EnosprayStatementDefinitions['PaintBusinessStatementWorkOrderDto'][],
              selectable(row: EnosprayStatementDefinitions['PaintBusinessStatementWorkOrderDto']) {
                return !row.confirmed?.value
              },
              change(rows: EnosprayStatementDefinitions['PaintBusinessStatementWorkOrderDto'][]) {
                this.form.workOrders.selection.data = rows
              }
            },
            operation: {
              include: {
                async click(row: EnosprayStatementDefinitions['PaintBusinessStatementWorkOrderDto']) {
                  await this.form.exclude()
                  return this.form[this.store.userTenantId === 'ENOCH' ? 'getByEnoch' : 'getByTenant']()
                }
              },
              exclude: {
                async click(row: EnosprayStatementDefinitions['PaintBusinessStatementWorkOrderDto']) {
                  await this.form.include()
                  return this.form[this.store.userTenantId === 'ENOCH' ? 'getByEnoch' : 'getByTenant']()
                }
              },
              confirmed: {
                async click(row: EnosprayStatementDefinitions['PaintBusinessStatementWorkOrderDto']) {
                  this.form.workOrders.selection.data = [row]
                  await this.form[row.confirmed?.value ? 'cancel' : 'active']()
                  return this.form[this.store.userTenantId === 'ENOCH' ? 'getByEnoch' : 'getByTenant']()
                }
              }
            },
            comment: {
              async blur(row: EnosprayStatementDefinitions['PaintBusinessStatementWorkOrderDto']) {
                await this.form[this.store.userTenantId === 'ENOCH' ? 'commentByEnoch' : 'commentByTenant']({ addition: row })
                return this.form[this.store.userTenantId === 'ENOCH' ? 'getByEnoch' : 'getByTenant']()
              }
            }
          }
        }
      },
      dialog: {
        visible: false
      },
      detail: {
        visible: false,
        confirm() {
          this.form[this.store.userTenantId === 'ENOCH' ? 'getByEnoch' : 'getByTenant']()
        }
      },
      receipt: {
        visible: false
      }
    }
  }
})
</script>
